body {
  padding: 0;
  margin: 0;
  color: #534741 !important;
  background-color: #fdfdfd !important;
}

.navbar a {
  color: #cbd1d5 !important;
  padding: 10px;
}

.navbar a:hover {
  color: white !important;
}
.navbar-brand {
  color: aliceblue !important;
  padding-left: 15px;
}

.title-div {
  min-height: 75px;
  color: aliceblue;
}
.main-div {
  display: grid;
  grid-template-rows: 0.028fr 0.02fr 0.01fr;
}
.p-card {
  margin-top: 50px;
  margin: 22px;
}

.p-card-image {
  border-radius: 100px;
  width: 100px;
  height: 100px;
  box-shadow: 0px 0px 3px 1px #ccc;
}
.p-card-info {
  margin-top: 10px;
  min-height: 50px;
  max-width: 150px;
}
.p-card-title {
  font-size: 24px;
  margin: 0px;
}
.p-card-subtitle {
  font-size: 14px;
  margin: 0px;
}

.topSide {
  background-image: url("./assets/images/backgroundTop.png");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #534741;
}

.middle {
  background-image: linear-gradient(to right, #3c2c52, #75b531);
  height: 10px;
}
.bottonSide {
  margin-top: 25px;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 20px;
  grid-template-rows: 1fr 1fr;
}
.home-icons {
  justify-self: center;
  padding: 10px;
  margin-left: 30px;
  margin-right: 30px;
  border: none;
  height: 130px;
  width: 130px;
  border-radius: 130px;
}
.center-icon {
  padding: 10px;
  border: none;
  height: 200px;
  width: 200px;
  border-radius: 200px;
}
.home-icons-div {
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

footer {
  border-top: grey;
  border: 3px;
  margin-top: 10px;
  margin-right: 30px;
  text-align: end;
  align-content: end;
}

.hero-text {
  font-size: 15px;
}
