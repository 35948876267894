.row {
  padding: 2px;
  /* margin-top: 5px; */
}

.contact-form {
  /* width: 60%; */
  display: grid;
  border-radius: 10px;
  grid-template-columns: 1fr 1fr;
}

textarea {
  margin: 0 !important;
  height: 150px;
}

form {
  display: flex;
  justify-content: center;
}
.contact-left {
  padding: 20px;
  padding-top: 0;
  margin-top: 0;
  margin-left: 30px;
  width: 110%;
}
.contact-right {
  padding: 2px;
  padding-left: 50px;
}
.contact-input {
  padding: 10px;
  border-radius: 10px;
  font-size: none;
  width: 80%;
}

.btn {
  width: 80%;
}

input:focus,
textarea:focus {
  box-shadow: 0 0px 5px rgba(81, 203, 238, 1);
  background-color: #ededed;
}
